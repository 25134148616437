import { Component, Input, OnInit } from '@angular/core';
import { AlertController, IonNav, ModalController, PopoverController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { ReinitService } from 'src/app/services/reinit.service';
import { Subscription } from 'rxjs';
import { CaseDetailPage } from '../case-detail/case-detail.page';
import { PaymentOptionPage } from '../payment-plan/payment-option/payment-option.page';
import { PersonalDetailPage } from '../personal-detail/personal-detail.page';
import { PaymentService } from 'src/app/services/payment.service';
import { AuthService } from 'src/app/services/auth.service';
// import { ArrangementPage } from '../arrangement/arrangement.page';
import { PaymentPlanRequestPage } from '../payment-plan-request/payment-plan-request.page';
import { PaymentPlanOptionComponent } from './payment-plan-option/payment-plan-option.component';
import { ViewPaymentPlanPage } from '../view-payment-plan/view-payment-plan.page';
import { PaymentFrequencyPage } from '../payment-plan/payment-frequency/payment-frequency.page';
import { CommunicationPreferencesComponent } from './communication-preferences/communication-preferences.component';
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.page.html',
  styleUrls: ['./account-details.page.scss'],
})
export class AccountDetailsPage implements OnInit {
  debtorDetailsSubscription: Subscription;
  debtorDetails;
  arr_option: any = {};
  background_url = '';
  filteredShowCases = [];
  paymentPlanRequest: boolean = false;
  paymentPlanPerform: boolean = false;
  isIRClient=false;
  modal;
  allowOnlyGroupArrangementForMultipleCases:boolean = false;

  constructor(
    public caseService: CaseService,
    private commonService: CommonService,
    private reinitService: ReinitService,
    private ionNav: IonNav,
    public paymentService: PaymentService,
    private authService: AuthService,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private modalController: ModalController,
  ) {
    this.reinitService.reinit$.subscribe((shouldReinit) => {
      if (shouldReinit) {
        this.ionViewWillEnter(); // Manually trigger component reinitialization
      }
    });
  }
  ngOnInit() {
    if (localStorage.getItem('portal_settings')) {
      let settings = JSON.parse(localStorage.getItem('portal_settings'));
      if (settings?.portal_details?.background_url) {
        this.background_url = settings.portal_details.background_url;
      }

      //Used for allow group arrangement for multiple cases
      this.allowOnlyGroupArrangementForMultipleCases = settings.portal_details.allow_only_group_arrangement_for_multiple_cases ?? this.allowOnlyGroupArrangementForMultipleCases;
    }
    const url = new URL(localStorage.getItem('server_url'));
    const subdomain = url.hostname.split('.').shift();
    this.isIRClient = subdomain === 'ir';
    if(!localStorage.getItem('isConsent')){
      this.openModal();
    }
  }
  ngOnDestroy(): void {
    if (this.debtorDetailsSubscription) {
      this.debtorDetailsSubscription.unsubscribe();
    }
  }

  ionViewWillEnter() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.arr_option) {
      this.arr_option = portalSettings?.arr_option
        ? portalSettings?.arr_option
        : { request: true, perform: false };
    }
    this.paymentPlanRequest = this.arr_option.request;
    this.paymentPlanPerform = this.arr_option.perform;
  }

  async presentActionSheet(caseData) {
    this.paymentService.presentActionSheet(caseData);
  }

  async goToProfileDetail(profileData) {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    this.ionNav.push(CaseDetailPage, { selectedProfile: profileData, portalSettings : portalSettings});
  }

  async goToPersonalDetail(myNav) {
    myNav.insert(1, PersonalDetailPage);
  }
  async goToPaymentOption(caseData) {
    this.caseService.setPaymentData({
      caseId: caseData.id,
      caseData,
    });
    this.ionNav.push(PaymentOptionPage, {
      caseData,
      planType:'plan',
      debtorDetails: this.authService.debtorDetails,
    });
  }

  async goToPaymentViewOption(caseData) {
    this.ionNav.push(ViewPaymentPlanPage, {
      caseId: caseData.id,
      currentCase: caseData,
    });
  }

  getCaseOutStanding(caseData) {
    return caseData?.d_outstanding.replace(caseData?.currency_symbol, '');
  }

  getOutstandingBalance(data) {
    if (!data.d_outstanding) return '-';
    else {
      if (isNaN(data.d_outstanding[0])) return data.d_outstanding;
      else if (data.currency_symbol) {
        return data.currency_symbol + data.d_outstanding;
      }
      return data.d_outstanding;
    }
  }

  async goToPaymentPlanProposal(caseData) {
    this.ionNav.push(PaymentPlanRequestPage, {
      caseId: caseData.id,
      totalOutstanding: caseData.d_outstanding,
      isGroupArrangement: false,
      debtorDetails: this.authService.debtorDetails,
    });
  }

  async presentPopover(ev: any, item: any) {
    const popover = await this.popoverController.create({
      component: PaymentPlanOptionComponent,
      componentProps: {
        case: item,
      },
      event: ev,
      translucent: true,
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data?.case && typeof this[data?.type] === 'function') {
      this[data.type](data.case);
    }
  }

  async GroupPlanPopover(ev:any,caseList:any){
    let caseIds = caseList.map((caseItem: any) => caseItem.id);
    let inputs;
    let showCases = caseList.filter(ele => {
      return ele.arranagement.current_arrangement.length==0;
    });
     
    await this.filterGroupArrangement(caseIds, showCases);
    if(this.filteredShowCases.length>0){
        inputs = this.filteredShowCases.map((c) => {
        return {
          name: 'selected_gateway',
          type: 'checkbox',
          label: c.client.client_title +', '+' Outstanding : ' + c.d_outstanding,
          value: c,
          checked:true,
        };
      });
      const alert = await this.alertController.create({
        header: 'Select Accounts for Group Payment',
        inputs: inputs,
        cssClass:'custom-alert-class',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Continue',
            role: 'OK',
            handler: async (selectedCases) => {
              if (!selectedCases || selectedCases.length < 2) {
              const warningAlert = await this.alertController.create({
                header: 'Selection Error',
                message: 'Please select at least two accounts.',
                buttons: ['OK'],
              });
              await warningAlert.present();
              return false; 
            }
              this.caseService.setPaymentData({
                caseData : selectedCases,
                paymentOption:'paymentPlan'
              });
              this.ionNav.push(PaymentFrequencyPage, {
                caseData : selectedCases,
                planType : 'group_plan',
                debtorDetails: this.authService.debtorDetails,
              });
            },
          },
        ],
      });
      await alert.present();
    }
    else if(this.filteredShowCases.length ==0){
    const alert = await this.alertController.create({
      header: 'Select Accounts for Group Payment',
      message: 'All cases already have a group arrangement',
      
    
      cssClass:'custom-alert-class',
      buttons: [
        {
          text: 'Ok',
          role: 'OK',
        },
      ],
    });
    await alert.present();

  }
    
    }

  async filterGroupArrangement(caseIds , showCases){
    return new Promise<void>((resolve, reject) => {
    this.caseService.getGroupArrangement(caseIds).subscribe((res: any) => {
      if (res.result && res.data?.group_arrangement_cases) {
        this.filteredShowCases = showCases.filter(showCase => !res.data.group_arrangement_cases.includes(showCase.id));
       }
       else{
        this.filteredShowCases = showCases;
       }
       resolve(); 
      } ,
       error =>{
        reject(error);
       
     });
  });
}
     
  async openModal() {
    const modal = await this.modalController.create({
      component: CommunicationPreferencesComponent,
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
}
}